<template>
  <div class="order-list">
    <div
      v-for="(item, i) in list"
      :key="i"
      class="order-item"
      @click="$router.push(`/apply-detail?bizNo=${item.bizNo}`)"
    >
      <div class="block-up">
        <div class="tit">
          <span>{{ item.capitalProductName }}</span>
          <div :class="getColor(item.appStatus)">{{ item.appStatusName }}</div>
        </div>
        <div class="apl-info">
          <div class="a-i-item">
            <div class="val">
              {{
                +item.appStatus >= 110 ? item.approvalAmount : item.applyAmount
              }}
            </div>
            <div class="name">
              {{ +item.appStatus >= 110 ? "授信金额" : "申请金额" }}
            </div>
          </div>
          <div class="a-i-item">
            <div class="val">
              {{ +item.appStatus >= 110 ? item.approvalTerm : item.applyTerm }}
            </div>
            <div class="name">
              {{ +item.appStatus >= 110 ? "授信期数" : "申请期数" }}
            </div>
          </div>
        </div>
      </div>
      <div class="block-down">
        <div>
          申请日期：<span>{{ item.applyTime }}</span>
        </div>
        <div>
          订单编号：<span>{{ item.bizNo }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { orderList } from "@/api/apply";
export default {
  data() {
    return {
      list: []
    };
  },
  created() {
    const { productNo, invitationCode, merchantNo } = this.$route.query;
    if (productNo) {
      const productInfo = {
        productNo,
        invitationCode,
        merchantNo
      };
      window.sessionStorage.setItem(
        "_app_cache_url_params",
        JSON.stringify(productInfo)
      );
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      try {
        this.$toast.loading({
          forbidClick: true,
          duration: 0
        });
        const { data } = await orderList();
        this.list = data || [];
        this.$toast.clear();
      } catch (error) {
        this.$errMsg(error);
      }
    },
    getColor(v) {
      if (+v < 0 || +v == 160) return "cr";
      else if (+v == 150) return "cg";
      else return "cb";
    }
  }
};
</script>
<style lang="scss" scoped>
.order-list {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 40px 32px;
  overflow-y: auto;
  box-sizing: border-box;
  background: linear-gradient(
    to bottom,
    #3fc2ff 0%,
    #60b1ff 25%,
    #acd8ff 40%,
    #ebf6ff 100%
  );
  * {
    box-sizing: border-box;
  }
  .order-item {
    border: 1px solid #efefef;
    background: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    padding: 32px;
    margin-bottom: 40px;
    .block-up {
      border-bottom: 1px solid #efefef;
      .tit {
        display: flex;
        justify-content: space-between;
        font-size: 28px;
        color: #333;
        span {
          font-weight: bold;
          font-size: 32px;
        }
        div {
          color: #52c41a;
          &.cr {
            color: #ee0a24;
          }
          &.cb {
            color: #1890ff;
          }
          &.cg {
            color: #52c41a;
          }
          &.co {
            color: #faad14;
          }
        }
      }
      .apl-info {
        display: flex;
        margin: 24px 0;
        align-items: center;
        justify-content: center;
        .a-i-item {
          width: 50%;
          text-align: center;
          .val {
            font-size: 58px;
            &::after {
              content: "元";
              font-size: 12px;
              margin-left: 10px;
            }
          }
          &:last-child .val::after {
            content: "期";
          }
          .name {
            font-size: 12px;
          }
        }
      }
    }
    .block-down {
      margin-top: 20px;
      & > div {
        line-height: 48px;
      }
    }
  }
}
</style>
